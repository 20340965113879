.ProjectBannerContainer {
    background-color: #F3F3F3;
}

.ProjectImageCol {
    padding-top: 80px;
    max-width: 100%;
}

.projectBannerImage {
    padding-bottom: 5rem;
    max-width: 100%;
    max-height: 75vh;
}