.projectCard {
    padding-top: 6em;
    padding-bottom: 6em;
    position: relative;
    z-index: 9;
}

.projectCol {
    flex: 0 0 50%;
    margin-top: auto;
    margin-bottom: auto;
}

.projectLink {
    border: 0;
    display: inline-block;
    position: relative;
    width: 70%;
    transition: all 0.75s;
    text-align: left;
    text-decoration-line: none;
}

.projectImage {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: contain;
    background-color: #F1F1F1;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: auto;
}

.projectLink:hover {
    transform: scale(0.9);
    text-decoration-line: underline;
}

h2.proj-title-main {
    margin-top: 10px;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: #000000;
    text-decoration: none;
}

.projDesc {
    font-size: 1.25em;
    font-weight: 300;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #4E4E4E;
}

