.masterContainer {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;
  padding-left: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Inter", sans-serif;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 1680px) {

  .masterContainer {
    max-width: 1400px !important;
  }
}

@media only screen and (min-width : 1200px) {

  .container { width: 1500px; }

}

@media screen and (max-width: 1280px) {

  .masterContainer {
    max-width: 1140px;
  }

}

@media screen and (max-width: 980px) {

  .masterContainer {
    max-width: 960px;
  }

}

@media screen and (max-width: 736px) {

  .masterContainer {
    max-width: 720px;
  }

}

@media screen and (max-width: 480px) {

  .masterContainer {
    max-width: 460px;
  }

}