.aboutBannerRow {
    padding-top: 80px;
    max-width: 100%;
    padding-bottom: 80px;
    min-height: 90vh;
}

.aboutTitleCol {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10rem;
}

.aboutDescCol {
    margin-top: auto;
    margin-bottom: auto;
}

.aboutJobName {
    font-size: 5rem;
    line-height: 6rem;
    color: #000000;
    font-weight: 400;
}

.aboutNameDiv {
    border-bottom: 1px solid #000;
}

.aboutJobDiv {
    padding-top: 20px;
}

.aboutJobTitle {
    font-size: 18px;
    font-weight: 700;
}

.aboutDetails {
    font-size: 18px;
}

.imageDiv {
    width: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
}

.aboutProfilePic {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.detailDiv {
    margin-top: 30px;
}