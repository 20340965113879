body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, input, select, textarea {
  color: #F9F9F9;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.65;
}

@media screen and (max-width: 1680px) {

  html, body, input, select, textarea {

    font-size: 12px;
  }

}

@media screen and (max-width: 1280px) {

  html, body, input, select, textarea {
    font-size: 12px;
  }

}

@media screen and (max-width: 980px) {

  html, body, input, select, textarea {
    font-size: 10px;
  }

}

@media screen and (max-width: 736px) {

  html, body, input, select, textarea {
    font-size: 10px;
  }

}

@media screen and (max-width: 480px) {

  html, body, input, select, textarea {
    font-size: 8px;
  }

}